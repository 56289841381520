import React, { useEffect, useRef, useState } from "react";
import { ListItem } from "@components/SelectorTireItem";
import { useTranslation } from "react-i18next";

interface Props {
	currentTiresListKeys: string[];
	onClick: (selectedTire: string) => void;
}

const SelectorTireDialog: React.FC<Props> = ({
	currentTiresListKeys,
	onClick
}) => {
	const { t } = useTranslation("translation");

	const closeDialog = () => {
		document.body.style.overflow = "auto";
		setFilter('');
		const dialog = document.getElementById('dialogAllSizes') as HTMLDialogElement | null;
		if (!dialog) return;
		dialog.classList.add("close-animate");
		setTimeout(() => {
			dialog.close();
			dialog.classList.remove("close-animate");
		}, 300);
	}

	const [filter, setFilter] = useState('');
	return (
		<dialog className="bg-gray-100 max-h-screen max-w-[100vw] w-full h-full md:w-[1200px] md:h-[580px] md:rounded p-8 shadow border-2" id="dialogAllSizes" aria-label="List of tire widths dialog">
			<div className="flex flex-wrap justify-between items-center" role="heading" aria-label="Dialog heading">
				<p className="md:flex-1 uppercase text-xl font-bold relative pl-4 before:absolute before:w-1.5 before:h-8 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-yellow-400 before:rounded">{t('selector.seeAllSizes')}</p>
				<div className="md:flex-1 flex items-center justify-center gap-3 text-center order-3 sm:order-2 my-4 sm:my-0">
					<input className="w-32 py-2 px-4 rounded border" type="text" maxLength={3} id="searchInput" value={filter} placeholder="Filtrar" onChange={event => setFilter(event.target.value)} aria-label="Filter" />
				</div>
				<div className="md:flex-1 text-right order-2 sm:order-3">
					<button title={t('close')} onClick={() => closeDialog()}><i className="fa-solid fa-circle-xmark fa-2x text-red-700 transitionTransform"></i></button>
				</div>
			</div>

			<span className="mt-2 text-gray-400 uppercase text-sm">{currentTiresListKeys.filter(tire => tire.includes(filter) || filter === '').length} {t('results.results')}</span>

			<div className="grid grid-cols-4 gap-1 md:grid-cols-6 md:gap-2 mt-6" role="list" aria-label="List of tire widths section">
				{currentTiresListKeys.filter(tire => tire.includes(filter) || filter === '').map((currentTire: string, index: number) => (
					<ListItem key={index} onClick={() => { closeDialog(); onClick(currentTire) }}>{currentTire}</ListItem>
				))}
			</div>
		</dialog>
	);
};

export default SelectorTireDialog;
