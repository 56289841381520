import { useTranslation } from "react-i18next";
import Button from "./ui/Button";
import Dialog from "./ui/Dialog";

interface Props {
	currentStep: number,
}

const TireImage: React.FC<Props> = ({
	currentStep
}) => {
	const { t, i18n } = useTranslation("translation");

	const dialogID = "notHaveDataDialog";
	const openDialog = () => {
		document.body.style.overflow = "hidden";
		const dialog = document.getElementById(dialogID) as HTMLDialogElement | null;
		if (!dialog) return;
		dialog.showModal();
	}

	const notHaveDataDialogTags = ['width', 'serie', 'rim', 'ic', 'cv'];

	const width = currentStep > 1 ? localStorage.getItem('width') ?? '205' : '205';
	const serie = currentStep > 2 ? localStorage.getItem('serie') ?? '55' : '55';
	const llanta = 'R ' + (currentStep > 3 ? localStorage.getItem('llanta') ?? '16' : '16');
	const ic = currentStep > 4 ? localStorage.getItem('ic') ?? '91' : '91';
	const cv = currentStep > 5 ? localStorage.getItem('cv') ?? 'V' : 'V';

	return (
		<div className="flex flex-col justify-between items-center relative">
			<div className="flex flex-col items-center justify-between h-full">
				<div className="inline-flex flex-row items-center gap-1">
					<img className="inline" height="20" width="20" src="/icons/roda.png" alt="pneumatic" />
					<span id="findTire" className="w-fit uppercase text-gray-700 font-bold" style={{ 'fontSize': 'large' }}>{t("index.findTire")}</span>
				</div>

				<img className="hidden md:flex" width="540" height="143" src="/img/banners/pneumatic-selector.png" alt="pneumatic-selector" />
				{/* <div role="desktopImg" className="relative hidden md:flex text-white text-3xl">
					<img width="450" height="133" src="/img/banners/pneumatic-selector_roda.png" alt="pneumatic-selector" />
					<span className={`absolute top-[47%] left-[17%] rotate-[-35deg] ${currentStep === 1 ? 'animate-pulse' : currentStep > 1 ? 'text-[#F6D000]' : ''} `}>{width} /</span>
					<span className={`absolute top-[28%] left-[32%] rotate-[-22deg] ${currentStep === 2 ? 'animate-pulse' : currentStep > 2 ? 'text-[#F6D000]' : ''}`}>{serie}</span>
					<span className={`absolute top-[20%] left-[44%] ${currentStep === 3 ? 'animate-pulse' : currentStep > 3 ? 'text-[#F6D000]' : ''}`}>{llanta}</span>
					<span className={`absolute top-[30%] left-[63%] rotate-[25deg] ${currentStep === 4 ? 'animate-pulse' : currentStep > 4 ? 'text-[#F6D000]' : ''}`}>{ic}</span>
					<span className={`absolute top-[46%] left-[74%] rotate-[35deg] ${currentStep === 5 ? 'animate-pulse' : currentStep > 5 ? 'text-[#F6D000]' : ''}`}>{cv}</span>
				</div> */}

				<img className="md:hidden" width="540" height="143" src="/img/banners/pneumatic-selector.png" alt="pneumatic-selector" />
				{/* <div role="mobileImg" className="relative md:hidden text-white text-2xl">
					<img className="max-w-[300px] min-w-[300px]" width="450" height="133" src="/img/banners/pneumatic-selector_roda.png" alt="pneumatic-selector" />
					<span className={`absolute top-[42%] left-[17%] transform [-translate-x-2/3 -translate-y-2/3] rotate-[-33deg] ${currentStep === 1 ? 'animate-pulse' : currentStep > 1 ? 'text-[#F6D000]' : ''} `}>{width}/</span>
					<span className={`absolute top-[24%] left-[32%] rotate-[-20deg] ${currentStep === 2 ? 'animate-pulse' : currentStep > 2 ? 'text-[#F6D000]' : ''}`}>{serie}</span>
					<span className={`absolute top-[18%] left-[44%] ${currentStep === 3 ? 'animate-pulse' : currentStep > 3 ? 'text-[#F6D000]' : ''}`}>{llanta}</span>
					<span className={`absolute top-[28%] left-[63%] rotate-[25deg] ${currentStep === 4 ? 'animate-pulse' : currentStep > 4 ? 'text-[#F6D000]' : ''}`}>{ic}</span>
					<span className={`absolute top-[43%] left-[74%] rotate-[44deg] ${currentStep === 5 ? 'animate-pulse' : currentStep > 5 ? 'text-[#F6D000]' : ''}`}>{cv}</span>
				</div> */}
			</div>

			{/* button to open dialog 'No tinc aquestes dades' */}
			<div className="mt-2 min-h-[24px]">
				<Button type="underline" icon="fa-circle-question md:group-hover:text-[#FFD100] transition-colors duration-500" animation={false} position="left" title="true" ariaLabel={t("selector.notHaveData")} onClick={openDialog}>
					<span>{t("selector.notHaveData")}</span>
				</Button>
			</div>

			{/*  DIALOG 'No tinc aquestes dades' */}
			<Dialog id={dialogID} title={t('selector.notHaveData')}>
				<div className="mt-4 text-lg">
					<p>{t('selector.notHaveDataDialog.title')}</p>
					<div className="flex items-center justify-center my-5">
						<img loading="lazy" decoding="async" srcSet={`/img/banners/tireWithLabels_${i18n.language}.png`} />
					</div>
					<ol className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 list-decimal font-bold">
						{
							notHaveDataDialogTags.map((item, index) => (
								<li key={index}>
									<p className="text-lg">{t(`selector.titles.${item}`)} del {t('results.tire')}</p>
									<p className="textWrapBalance font-normal">
										{t(`selector.notHaveDataDialog.${item}`)}
									</p>
								</li>
							))
						}
					</ol>
				</div>
			</Dialog>
		</div>
	);
};

export default TireImage;
