import React, { useEffect, useRef, useState } from "react"
import type { Tire } from "@interfaces/Tire";
import { EstacionEnum, type Estacion } from "@interfaces/TirePromotion";
import { useTranslation } from "react-i18next";
import { ListItem, SeasonItem } from "@components/SelectorTireItem.tsx";
import SelectorTireDialog from "@components/SelectorTireDialog.tsx";
import TireImage from "@components/TireImage";
import Button from "@components/ui/Button";
import { isMobileDevice } from "@services/utils.service";
import Loading from "@components/ui/Loading";
import trans from "@routes";

export default function SelectorTireSearch(props: { tiresList: Tire[], onlySeason: boolean }) {
	const { t, i18n } = useTranslation("translation");
	const [currentStep, setCurrentStep] = useState(1);
	const [showContent, setShowContent] = useState(false);

	const stepsTitles: { [key: number]: string } = {
		0: 'season',
		1: 'tireWidth',
		2: 'tireProfile',
		3: 'tireDiameter',
		4: 'loadIndex',
		5: 'speedRating'
	};

	const stepsGuide: { [key: number]: string } = {
		0: 'season',
		1: 'width',
		2: 'serie',
		3: 'llanta',
		4: 'ic',
		5: 'cv'
	};

	const [stepData, setStepData] = useState({
		season: '',
		width: 0,
		serie: 0,
		llanta: 0,
		ic: 0,
		cv: 0,
	});


	//Show or not 'last search button'
	const [lastSearch, setlastSearch] = useState<string | null>(null);
	useEffect(() => {
		//if only season set step 2
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get('season')) {
			setStepData({ //save selected tire in current step
				...stepData,
				[stepsGuide[currentStep - 1]]: urlParams.get('season')
			});
			setCurrentStep(2);
		}

		//have last search?
		if (localStorage.getItem('width')) {
			setlastSearch(localStorage.getItem('width') + "/" + localStorage.getItem('serie') + " R" + localStorage.getItem('llanta') + " " + localStorage.getItem('ic') + localStorage.getItem('cv'));
		} else setlastSearch(null);

		setShowContent(true);
	}, []);

	const openDialog = () => {
		document.body.style.overflow = "hidden";
		const dialog = document.getElementById('dialogAllSizes') as HTMLDialogElement | null;
		if (!dialog) return;
		dialog.showModal();
	}

	const handleStartAgain = () => {
		setCurrentStep(1);
		setAllTiresListJSON(props.tiresList);
		setcurrentTiresListKeys(defaultKeysStep1);
	}

	const saveToLocalStorageAndRedirect = () => {
		const values = Object.values(stepsGuide).slice(0, -1);
		for (const value of values) {
			localStorage.setItem(value, stepData[value as keyof typeof stepData].toString());
		}

		window.location.href = trans("resultats");
	}

	const alignTopFirstStep = () => {
		if (!document.getElementById('findTire')) return;
		const yOffset = document.getElementById('findTire')!.getBoundingClientRect().top; // Calcula la posición del título relativa a la ventana
		window.scrollTo({
			top: (window.scrollY + yOffset) - 50, // align title to top
			behavior: "smooth"
		});
	}

	const handleNextStep = (selectedTire: string) => {
		if (props.onlySeason) { window.location.href = trans("cercador") + "?season=" + selectedTire; return; }
		if (currentStep === 1 && isMobileDevice()) alignTopFirstStep(); //if is mobile align section to top

		//save selected tire in current step
		setStepData({
			...stepData,
			[stepsGuide[currentStep - 1]]: selectedTire,
		});

		//FIRST STEP: SEASON
		if (currentStep === 1) { setCurrentStep(currentStep + 1); return; }

		//THE LAST STEP: FINISH
		if (currentStep === Object.keys(stepsTitles).length) { localStorage.setItem('cv', selectedTire); saveToLocalStorageAndRedirect(); return; }

		//set tires json and key list
		const currentAll = allTiresListJSON[selectedTire];
		const currentList = currentAll[stepsGuide[currentStep]];
		setAllTiresListJSON(currentList);
		setcurrentTiresListKeys(keysFromJSON(currentList));

		//go to next step
		setCurrentStep(currentStep + 1);
	};

	// TODO: Add prev step
	// const handlePrevStep = () => {
	// 	setCurrentStep(currentStep - 1);
	// };

	const [allTiresListJSON, setAllTiresListJSON] = useState<any>(props.tiresList);
	var tiresList: string[] = keysFromJSON(allTiresListJSON);
	const defaultKeysStep1 = ["175", "185", "195", "205", "215", "225"];
	const [currentTiresListKeys, setcurrentTiresListKeys] = useState<string[]>(defaultKeysStep1);

	if (showContent == false) return <Loading />;
	if (tiresList.length > 0) {
		return ( // if there is an error don't show this section
			<section className={"grid grid-cols-1 md:grid-cols-2 gap-4 mt-3 bg-white p-4 shadow rounded border" + (currentTiresListKeys.length > 0 ? 'block' : 'hidden')}>
				<SelectorTireDialog currentTiresListKeys={tiresList} onClick={handleNextStep} />

				<TireImage currentStep={currentStep} />

				<div className={`${!showContent ? 'invisible' : ''}`}>
					<div className="grid grid-cols-[75%_25%] items-center justify-between mb-2">
						{/* <Button onClick={() => handlePrevStep()} ariaLabel={t('back')} title='true' position="hidden"> <i className="fa-solid fa-arrow-left"></i> </Button> */} {/* BACK BUTTON */}
						<span className="w-fit uppercase text-gray-700 font-bold relative pl-3 md:mb-0 before:absolute before:w-1.5 before:h-6 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-yellow-400 before:rounded" style={{ 'fontSize': 'large' }}> {t("selector." + stepsTitles[currentStep - 1])}</span>
						{ProgressBar(currentStep)}
					</div>

					<ul className={`grid ${currentStep === 1 ? 'grid-cols-3' : currentTiresListKeys.length > 6 ? 'grid-cols-3 md:grid-cols-6' : 'grid-cols-3'} grid-flow-dense gap-2 bg-gray-100 p-4 items-start min-h-[166px] rounded ${currentStep === 1 ? ' hidden' : ''}`} role="list">
						{currentTiresListKeys.map((currentTire, index) => (
							<ListItem key={index} onClick={() => handleNextStep(currentTire)}>{currentTire}</ListItem>
						))}
					</ul>

					<ul className={'grid grid-cols-1 md:grid-cols-3 gap-3 bg-gray-100 items-start min-h-[166px] p-4 rounded' + (currentStep === 1 ? '' : ' hidden')} role="list">
						<SeasonItem seasonIcon="sun" seasonTitle={EstacionEnum.SUMMER} onClick={() => { handleNextStep(EstacionEnum.SUMMER) }} />
						<SeasonItem seasonIcon="snow" seasonTitle={EstacionEnum.WINTER} onClick={() => { handleNextStep(EstacionEnum.WINTER) }} />
						<SeasonItem seasonIcon="all" seasonTitle={EstacionEnum.ALLSEASONS} onClick={() => { handleNextStep(EstacionEnum.ALLSEASONS) }} />

						{
							currentStep === 1 && (
								<div className="col-span-3 hidden md:flex items-center justify-center">
									<p className="text-sm text-gray-500">{t('selector.selectTire')}</p>
								</div>
							)
						}
					</ul>

					{/* if user have previous search (saved in localStorage), show button */}
					<div className={'grid grid-cols-2 justify-center items-center gap-4 mt-6 md:mt-2 min-h-[24px]'}>
						<div className="flex justify-end">
							{currentStep === 2 && (
								<Button onClick={openDialog} styles="w-fit group flex flex-row items-center gap-1" ariaLabel={t('selector.seeAllSizes')} title="true" position="hidden" type="simple">
									<i className="fa-solid fa-circle-plus fa-lg stroke-red-500"></i>
									<span className="uppercase text-sm">{t('selector.seeAllSizes')}</span>
								</Button>
							)}
							{currentStep > 1 && currentStep !== 2 && (
								<Button styles="w-fit" onClick={handleStartAgain} type="simple" position="left" title="true" ariaLabel={t('selector.startAgain')}>
									{t("selector.startAgain")}
								</Button>
							)}
						</div>
						<p className={`group text-sm text-gray-500 ${currentStep === 1 ? 'flex justify-end' : ''}`}>
							{lastSearch && <a title={t('selector.lastSearch')} href={trans("resultats")}> <i className="fa-solid fa-magnifying-glass-arrow-right md:group-hover:text-[#FFD100] transition-colors duration-500"></i> <span className="underline">[{lastSearch}]</span></a>}
						</p>
					</div>

				</div>

			</section>
		);
	}
}

function ProgressBar(currentStep: number) {
	return (
		<div className="w-full h-6 bg-gray-300 rounded-lg overflow-hidden" role="progressbar" aria-label="progress bar steps">
			<div className={`h-full bg-[#FFCC00] pl-2 font-bold text-xs flex items-center`} style={{ transition: 'width 0.5s', 'width': (currentStep / 6) * 100 + '%' }}>{currentStep}/6</div>
		</div>
	);
}


const keysFromJSON = (value: any) => {
	if (!value) return [''];
	return Object.keys(value).map((key) => {
		return key.toString();
	});
};
