import { useTranslation } from "react-i18next";

interface Props {
	id: string;
	title: string;
	children: React.ReactNode;
}

const Dialog: React.FC<Props> = ({
	id,
	title,
	children
}) => {
	const { t } = useTranslation("translation");

	const closeDialog = () => {
		document.body.style.overflow = "auto";
		const dialog = document.getElementById(id) as HTMLDialogElement | null;
		if (!dialog) return;
		dialog.classList.add("close-animate");
		setTimeout(() => {
			dialog.close();
			dialog.classList.remove("close-animate");
		}, 300);
	}

	return (
		<dialog className="bg-white max-h-screen max-w-[100vw] w-full h-full md:w-[1200px] md:h-[580px] md:rounded p-8 shadow" id={id}>
			<div className="flex flex-wrap justify-between items-center" role="heading" aria-label={`Heading ${title} dialog`}>
				<p className="md:flex-1 uppercase text-xl font-bold relative pl-4 before:absolute before:w-1.5 before:h-8 before:left-0 before:top-1/2 before:-translate-y-1/2 before:bg-yellow-400 before:rounded">{title}</p>
				<div className="md:flex-1 text-right order-2 sm:order-3">
					<button title={t('close')} onClick={() => closeDialog()}><i className="fa-solid fa-circle-xmark fa-2x text-red-700 md:hover:rotate-90 transitionTransform"></i></button>
				</div>
			</div>

			<hr className="border my-4 rounded" />

			{children}
		</dialog>
	);
};

export default Dialog;


//COM OBRIR UN DIALOG
// const openDialog = () => {
// document.body.style.overflow = "none";
// 	const dialog = document.getElementById('dialogID') as HTMLDialogElement | null;
// 	if (!dialog) return;
// 	dialog.showModal();
// }
