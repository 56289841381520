import type { Estacion } from "@interfaces/TirePromotion";
import React from "react";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface SeasonItemProps {
	seasonTitle: Estacion;
	seasonIcon: 'sun' | 'snow' | 'all';
	onClick: () => void;
}

interface ListItemProps {
	onClick: () => void;
	children: React.ReactNode
}

const SeasonItem: React.FC<SeasonItemProps> = ({
	seasonTitle,
	seasonIcon,
	onClick
}) => {
	const { t, i18n } = useTranslation("translation");

	return (
		<li className="flex flex-col items-center gap-2 bg-white rounded text-center border-2" style={{ transition: 'border-color 0.4s ease' }} role="listitem">
			{/* <button className="flex flex-col items-center w-full p-4" onClick={() => { onClick() }}>
				<figure>
					{seasonIcon === 'all' ? (
						<div className="flex gap-2">
							<img height="40" width="40" src={`/icons/weather/sun.svg`} alt="Weather sun icon" />
							<img height="40" width="40" src={`/icons/weather/snow.svg`} alt="Weather snow icon" />
						</div>
					) : (
						<img height="40" width="40" src={`/icons/weather/${seasonIcon}.svg`} alt={`Weather ${seasonIcon} icon`} />
					)}
					<figcaption>{t(`selector.weather.${seasonTitle.toLowerCase()}`)}</figcaption>
				</figure>
			</button> */}
			<button className="flex flex-col items-center w-full p-4" onClick={() => { onClick() }}>
				<figure className="grid grid-cols-2 items-center w-full md:block">
					<div className="flex flex-row gap-2 justify-end md:justify-center">
						{seasonIcon === 'all' ? (
							<>
								<img height="40" width="40" src={`/icons/weather/sun.svg`} alt="Weather sun icon" />
								<img height="40" width="40" src={`/icons/weather/snow.svg`} alt="Weather snow icon" />
							</>
						) : (
							<img height="40" width="40" src={`/icons/weather/${seasonIcon}.svg`} alt={`Weather ${seasonIcon} icon`} />
						)}
					</div>
					<figcaption className="ml-2 md:ml-0 text-start md:text-center">{t(`selector.weather.${seasonTitle.toLowerCase()}`)}</figcaption>
				</figure>
			</button>
		</li>
	);
}

const ListItem: React.FC<ListItemProps> = ({ children, onClick }) => {
	return (
		<li className="flex flex-col items-center gap-2 bg-white roundedtext-center border-2" style={{ transition: 'border-color 0.4s ease' }} role="listitem">
			<button className="w-full p-4" onClick={() => { onClick() }}>
				<span className="text-lg">{children}</span>
			</button>
		</li>
	);
}

export { SeasonItem, ListItem };
